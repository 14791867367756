import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"

const connectionString = (process.env.NODE_ENV === 'production')
    ? 'InstrumentationKey=18c79a4c-f74c-47bf-bc55-b3ca12f9186d;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/'
    : 'InstrumentationKey=d61e1c1e-f640-438d-bd19-e7d4ec45f113;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/'

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    }
});

appInsights.loadAppInsights();

export default reactPlugin
