import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import React from "react";
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appReducer from '../features/app/appSlice'
import appUserReducer from '../features/appUser/appUserSlice'
import baconSlice from "../features/bacon/baconSlice";
import creditSlice from "../features/credits/creditSlice";
import genresReducer from '../features/genres/genresSlice'
import movieReducer from '../features/movies/movieSlice'
import peopleReducer from '../features/people/peopleSlice'
import providersReducer from '../features/providers/providersSlice'
import refinementReducer from '../features/refinements/refinementSlice'
import reactPlugin from "./appInsights";

export const createStore = () => configureStore({
    reducer: {
        app: appReducer,
        appUser: appUserReducer,
        bacon: baconSlice,
        credits: creditSlice,
        genres: genresReducer,
        movies: movieReducer,
        people: peopleReducer,
        providers: providersReducer,
        refinements: refinementReducer,
    },
})

export const wrapWithProvider = ({ element }: any) => {
    const store = createStore()
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Provider store={store}>
                {element}
            </Provider>
        </AppInsightsContext.Provider>
    )
}

type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore["getState"]
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore["dispatch"]
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
