import { createSelector, createSlice } from "@reduxjs/toolkit"
import { executeFetchAsync, getUniqueList } from "../../globals/utilities"
import { AppThunk, RootState } from "../../context/store"
import { addMovies, Movie, selectMovies } from "../movies/movieSlice"
import { RefinementsState } from "../refinements/refinementSlice"
import genresData from './genresData.json'
import { baseApiUrl, guestAccessToken } from "../../context/apiConfig"

export type DiscoverRequest = {
    refinements: RefinementsState,
    genreId: number,
    page: number
}

export type Genre = {
    current_page: number
    description: string
    id: number
    movie_ids: number[],
    name: string
    query_params: string
    pageName: string
}

export type GenresState = {
    genreList: Genre[]
    workingUris: string[]
}

const initialState: GenresState = {
    genreList: genresData,
    workingUris: [],
}

export const genresSlice = createSlice({
    name: 'genres',
    initialState: initialState,
    reducers: {
        addGenreMovies: (state: GenresState, action) => {
            state.genreList = state.genreList.map(g => {
                if (g.id === action.payload.genreId) {
                    const movie_ids = getUniqueList(g.movie_ids, action.payload.movie_ids)
                    return {
                        ...g,
                        current_page: g.current_page + 1,
                        movie_ids,
                    }
                }
                else {
                    return g;
                }
            })
        },
        addWorkingUri: (state: GenresState, action) => {
            state.workingUris.push(action.payload)
        },
        removeGenreMovie: (state: GenresState, action) => {
            state.genreList = state.genreList.map(g => {
                return {
                    ...g,
                    movie_ids: g.movie_ids.filter(mId => mId !== action.payload),
                }
            })
        },
        removeWorkingUri: (state: GenresState, action) => {
            state.workingUris = state.workingUris.filter(w => w !== action.payload)
        },
        resetGenreList: (state: GenresState) => {
            state.genreList = genresData
        },
        setGenreList: (state: GenresState, action) => {
            state.genreList = action.payload
        },
    },
});

// Base Selectors
export const selectNumber = (_: any, n: number) => n;
export const selectString = (_: any, s: string) => s;
export const selectGenresBase = (state: RootState) => state.genres.genreList;
export const selectWorkingUrisBase = (state: RootState) => state.genres.workingUris;
export const selectGenresOrAll = (state: RootState, genreIds?: number[]) => {
    if (!genreIds || genreIds.length === 0)
        return state.genres.genreList
    else {
        const returnValue: Genre[] = []
        genreIds.forEach(id => {
            const g = state.genres.genreList.find(g => g.id === id)
            if (g)
                returnValue.push(g)
        });
    }
}

// Reselectors
export const selectGenres = createSelector(selectGenresBase, (items) => items);
export const selectGenreById = createSelector(selectGenresBase, selectNumber, (items: Genre[], id) => { return items.find(i => i.id === id) });
export const selectWorkingUris = createSelector(selectWorkingUrisBase, (items) => items);
export const selectGenreMovies = createSelector(
    selectMovies,
    selectString,
    selectGenresBase,
    (movies, pageName, genres) => {
        const returnValue: Movie[] = []
        const genre = genres.find(s => s.pageName === pageName)
        if (genre) {
            genre.movie_ids.forEach(mId => {
                const nm = movies.find(m => m.id === mId)
                if (nm) returnValue.push(nm)
            });
        }
        return returnValue;
    }
)
export const selectGenreByPageName = createSelector(
    selectGenresBase,
    selectString,
    (items, pageName) => {
        return items.find(i => i.pageName === pageName)
    });
export const selectMoviesByGenreId = createSelector(
    selectMovies,
    selectNumber,
    selectGenresBase,
    (movies, genreId, genres) => {
        const returnValue: Movie[] = []
        const genre = genres.find(s => s.id === genreId)
        if (genre) {
            genre.movie_ids.forEach(mId => {
                const nm = movies.find(m => m.id === mId)
                if (nm) returnValue.push(nm)
            });
        }
        return returnValue;
    }
)

// Methods
export const requestGenreMoviesAsync = (genreId: number): AppThunk => async (dispatch, getState) => {

    const s = getState();
    const genreList = s.genres.genreList
    const workingUris = s.genres.workingUris
    const refinements = s.refinements
    const genre = genreList.find(g => g.id === genreId)
    if (!genre) return
    const page = genre.current_page + 1;

    const workingUri = genreId + "|" + page
    const exists = workingUris.find(w => w === workingUri)
    if (exists) return
    dispatch(addWorkingUri(workingUri))

    const discoverRequest: DiscoverRequest = {
        refinements,
        page,
        genreId
    }
    if (!refinements.userId)
        refinements.userId = "guest@kixvu.com"

    // Hub
    // const rs = dispatch(invokeDiscoverMovies(discoverRequest))

    // API
    const url = baseApiUrl + "/discover/movies"
    const rs: Movie[] = await executeFetchAsync(url, discoverRequest, guestAccessToken)
    if (rs) {
        dispatch(addMovies(rs));
        const movie_ids: number[] = [];
        rs.forEach(movie => {
            movie_ids.push(movie.id)
        });
        dispatch(addGenreMovies({ genreId, movie_ids }))
    }

    dispatch(removeWorkingUri(workingUri))
};

export const { addGenreMovies, addWorkingUri, removeGenreMovie, removeWorkingUri,
    resetGenreList, setGenreList } = genresSlice.actions;
export default genresSlice.reducer;
