import { createSelector, createSlice } from "@reduxjs/toolkit"
import { executeFetchAsync, getUniqueListBy } from "../../globals/utilities"
import { baseApiUrl, guestAccessToken } from "../../context/apiConfig"
import { AppThunk, RootState } from "../../context/store"
import { Credits, CreditType } from "../credits/creditSlice"
import { Images } from "../media/mediaSlice"

export type Person = {
    adult: boolean
    biography: string
    character: string
    credits: Credits
    details_loaded: boolean
    id: number
    images: Images
    name: string
    profile_path: string
}

export type PeopleState = {
    list: Person[]
}

const initialState: PeopleState = {
    list: []
}

export const peopleSlice = createSlice({
    name: 'people',
    initialState,
    reducers: {
        addPeople: (state, action) => {
            state.list = getUniqueListBy("id", state.list, action.payload);
        },
        upsertPerson: (state, action) => {
            let updated = false;
            state.list = state.list.map(i => {
                if (i.id === action.payload.id) {
                    updated = true;
                    return action.payload;
                }
                else {
                    return i;
                }
            })
            if (!updated) {
                state.list.push(action.payload);
            }
        }
    },
});

// Base selectors
export const selectNumber = (_: RootState, n: number) => n;
export const selectPeopleBase = (state: RootState) => state.people.list;

// Reselectors
export const selectPeople = createSelector(
    selectPeopleBase,
    list => list
)
export const selectPersonById = createSelector(
    selectPeopleBase,
    selectNumber,
    (items, id) => {
        return items.find(i => i.id === id)
    }
);

// Methods
export const ensurePerson = (id: number): AppThunk => async (dispatch, getState) => {
    const list = getState().people.list
    const existing = list.find(m => m.id === id);

    if (!existing) {
        const url = baseApiUrl + '/people/' + id
        const rs: Person = await executeFetchAsync(url, null, guestAccessToken)
        dispatch(upsertPerson(rs))
    }
};
export const ensurePersonDetails = (id: number): AppThunk => async (dispatch, getState) => {
    const people = getState().people.list
    let returnValue = people.find(m => m.id === id);

    if (!returnValue || returnValue.details_loaded === false) {
        const url = baseApiUrl + '/people/' + id + '/details'
        returnValue = await executeFetchAsync(url, null, guestAccessToken)

        returnValue?.credits?.cast?.forEach(c => c.creditType = CreditType.Movie)
        returnValue?.credits?.crew?.forEach(c => c.creditType = CreditType.Movie)

        dispatch(upsertPerson(returnValue))
    }

    return returnValue
};
export const requestRandomPerson = (): AppThunk => async (dispatch) => {
    const url = baseApiUrl + '/people/random'
    var returnValue = await executeFetchAsync(url, null, guestAccessToken)
    dispatch(upsertPerson(returnValue))
    return returnValue
};

export const { addPeople, upsertPerson } = peopleSlice.actions;
export default peopleSlice.reducer;
