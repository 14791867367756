import { createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../context/store"
import { Movie } from "../movies/movieSlice"
import { Person } from "../people/peopleSlice"

export enum CreditType {
    Movie, Person
}

export type Credit = {
    adult?: boolean
    backdrop_path?: string
    cast_id?: number
    character?: string
    credit_id?: string
    creditType: CreditType
    department?: string
    genre_ids?: number[]
    id: number
    job?: string
    name?: string
    order?: number
    overview?: string
    poster_path?: string
    profile_path?: string
    release_date?: string
    title?: string
}

export type Credits = {
    cast: Credit[],
    crew: Credit[],
}

export const getCreditFromMovie = (movie: Movie) => {
    const returnValue: Credit = {
        id: movie.id,
        name: "",
        profile_path: "",
        cast_id: 0,
        character: "",
        credit_id: movie.id.toString(),
        creditType: CreditType.Movie,
        order: 0,
        genre_ids: [],
        overview: movie.overview,
        poster_path: movie.poster_path,
        release_date: movie.release_date,
        title: movie.title,
    }
    return returnValue
}

export const getCreditFromPerson = (person: Person) => {
    const returnValue: Credit = {
        id: person.id,
        name: person.name,
        profile_path: person.profile_path,
        cast_id: 0,
        character: person.character,
        credit_id: person.id.toString(),
        creditType: CreditType.Person,
        order: 0,
        backdrop_path: "",
        genre_ids: [],
        overview: "",
        poster_path: "",
        release_date: "",
        title: "",
    }
    return returnValue
}

export type CreditsState = {
    cardSize: number
}

const initialState: CreditsState = {
    cardSize: 150
}

export const creditsSlice = createSlice({
    name: 'credits',
    initialState: initialState,
    reducers: {
        setCardSize: (state: CreditsState, action) => {
            state.cardSize = action.payload
        },
    },
});

export const { setCardSize } = creditsSlice.actions;
export default creditsSlice.reducer;

// Base Selectors
export const selectNumber = (_: any, n: number) => n;
export const selectString = (_: any, s: string) => s;
export const selectCardSizeBase = (state: RootState) => state.credits.cardSize;

// Reselectors
export const selectCardSizePx = createSelector(selectCardSizeBase, (cardSize) => cardSize.toString() + 'px');
export const selectCardSizePer = createSelector(selectCardSizeBase, (cardSize) => cardSize.toString() + '%');
