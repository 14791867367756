import { createSelector, createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';
import { baseApiUrl, guestAccessToken, IResponse } from '../../context/apiConfig';
import { RootState, AppThunk } from '../../context/store';
import { executeFetchAsync, getStorageItem, setStorageItem, UiFunction } from '../../globals/utilities';
import { addMessage, addTask, deleteTaskByTitle, Message, reconnectToHub } from '../app/appSlice';
import { resetGenreList } from '../genres/genresSlice';
import { clearMovieList } from '../movies/movieSlice';
import { resetRefinements, setRefinements } from '../refinements/refinementSlice';

const storageKey = process.env.NODE_ENV + "-appUser"

export type ConfirmEmailModel = {
    userId: string
    code: string
}

export type ChangePasswordModel = {
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

export type SignInModel = {
    email: string,
    password: string,
    rememberMe: boolean,
}

export type RegisterUserModel = {
    email: string
    screenName: string
    password: string
    confirmPassword: string
}

export type UserToken = {
    accessToken: string
    expires: Date
    roles: string[]
    screenName: string
    userName: string
}

export type AppUserState = {
    seenGetStarted: boolean
    userToken: UserToken | null
    userData: object | null
}

const initialState: AppUserState = {
    seenGetStarted: false,
    userData: null,
    userToken: null,
}

export const appUserSlice = createSlice({
    name: 'appUser',
    initialState: getStorageItem(storageKey) || initialState,
    reducers: {
        clearToken: (state: AppUserState) => {
            state.userToken = null
            setStorageItem(storageKey, state);
        },
        setSeenGetStarted: (state: AppUserState, action) => {
            state.seenGetStarted = action.payload
            setStorageItem(storageKey, state);
        },
        setUserData: (state: AppUserState, action) => {
            state.userData = action.payload
        },
        setUserToken: (state: AppUserState, action) => {
            state.userToken = action.payload
            setStorageItem(storageKey, state);
        },
    },
});

// Base Selectors
export const selectAppUserBase = (state: RootState) => state.appUser;
export const selectUserToken = (state: RootState) =>
    (state.appUser.userToken?.accessToken && new Date(state.appUser.userToken.expires) > new Date())
        ? state.appUser.userToken.accessToken
        : null
export const selectAnyToken = (state: RootState) => {
    const userToken = selectUserToken(state)
    if (userToken && userToken.length > 0)
        return userToken
    else
        return guestAccessToken
}

// Reselectors
export const selectAppUser = createSelector(selectAppUserBase, (val: AppUserState) => val);
export const selectScreenName = createSelector(
    selectAppUserBase,
    (appUser: AppUserState) => {
        return (appUser?.userToken?.screenName || 'guest')
    })
export const selectSeenGetStarted = createSelector(selectAppUserBase, (val: AppUserState) => val.seenGetStarted)
export const selectUserData = createSelector(selectAppUserBase, (val: AppUserState) => val.userData);

// Methods
export const changePassword = (model: ChangePasswordModel): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const userToken = selectUserToken(state)
    const msg: Message = {
        displayTimeout: 5000,
        id: 0,
        uiFunction: UiFunction.Danger,
        details: 'Cannot change password, user is not signed in.',
        title: 'Error'
    }

    if (userToken) {
        const taskTitle = 'Changing password...'
        dispatch(addTask({ id: 0, title: taskTitle }))
        const url = baseApiUrl + "/account/changePassword";
        const rs: IResponse<string> = await executeFetchAsync(url, model, userToken)
        if (rs.userMessage && rs.userMessage.length > 0) {
            msg.details = rs.userMessage
        } else {
            msg.uiFunction = UiFunction.Success
            msg.title = 'Success'
            msg.details = 'Successfully changed password.'
        }
        dispatch(deleteTaskByTitle(taskTitle))
    }
    else {
        navigate('/', { replace: true })
    }

    dispatch(addMessage(msg))
};
export const confirmEmail = (confirmEmail: ConfirmEmailModel): AppThunk => async (dispatch) => {
    const taskTitle = 'Confirming email...'
    dispatch(addTask({ id: 0, title: taskTitle }))

    const url = baseApiUrl + '/account/confirmEmail';
    const rs: IResponse<string> = await executeFetchAsync(url, confirmEmail, guestAccessToken, 'POST', false)

    const msg: Message = {
        displayTimeout: 10000,
        id: 0,
        uiFunction: UiFunction.Success,
        details: 'Please log in with your new account.',
        title: 'Success!'
    }
    let toUrl = "/my/login/"

    if (rs.responseType > 0) {
        // Error
        msg.uiFunction = UiFunction.Danger
        msg.details = rs.userMessage
        msg.title = "Error"
        toUrl = '/'
    }

    dispatch(addMessage(msg))
    dispatch(deleteTaskByTitle(taskTitle))
    navigate(toUrl)
};
export const deleteAccount = (model: SignInModel): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const userToken = selectUserToken(state);
    const msg: Message = {
        displayTimeout: 5000,
        id: 0,
        uiFunction: UiFunction.Success,
        title: 'Successfully closed account'
    }

    if (userToken) {
        const url = baseApiUrl + '/account/deleteAccount';
        // const rs = 
        // dispatch(executeFetchAsync("/api/account/deleteAccount", model, result => {
        //     if (result) {
        //         dispatch(logoutUser())
        //         dispatch(clearUserRefinements())
        //         dispatch(addMsg("Successfully deleted account.", AlertColor.Success))
        //     }
        //     if (callback) callback(result)
        // }));
    }
    else {
        msg.title = 'Not signed in';
        msg.uiFunction = UiFunction.Danger;
        dispatch(addMessage(msg))
    }
}
export const downloadUserData = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const userToken = selectUserToken(state);
    if (userToken) {
        const url = baseApiUrl + '/account/downloadMyData';
        const rs = await executeFetchAsync(url, null, userToken);
        dispatch(setUserData(rs))
    }
};
export const forgotPassword = (email: string): AppThunk => async (dispatch) => {
    const taskTitle = "Forgot password..."
    dispatch(addTask({ id: 0, title: taskTitle }))
    const model = {
        email
    }
    const url = baseApiUrl + '/account/forgotPassword'
    const rs = await executeFetchAsync(url, model, guestAccessToken)

    const msg: Message = {
        uiFunction: UiFunction.Success,
        displayTimeout: 8000,
        id: 0,
        details: "Please follow the link to reset your password.",
        title: 'Email Sent'
    }
    dispatch(addMessage(msg))
    dispatch(deleteTaskByTitle(taskTitle))
}
export const loginUser = (signInModel: SignInModel): AppThunk => async (dispatch) => {
    const taskTitle = 'Signing in...'
    dispatch(addTask({ id: 0, title: taskTitle }))

    const loginUrl = baseApiUrl + '/account/login'
    const rs: UserToken = await executeFetchAsync(loginUrl, signInModel, guestAccessToken)

    if (rs && rs.accessToken) {
        dispatch(setUserToken(rs))
        const refinementUrl = baseApiUrl + '/refinements'
        const refinementRs = await executeFetchAsync(refinementUrl, null, rs.accessToken)
        dispatch(setUserData(null))
        dispatch(resetVisibleMovies())
        dispatch(setSeenGetStarted(true))
        dispatch(setRefinements(refinementRs))
        dispatch(deleteTaskByTitle(taskTitle))
        dispatch(reconnectToHub())
        navigate('/', { replace: true })
    }
    else {
        const res = rs as unknown as IResponse<string>
        if (res.userMessage && res.userMessage.length > 0) {
            const msg: Message = {
                displayTimeout: 5000,
                id: 0,
                uiFunction: UiFunction.Danger,
                details: res.userMessage,
                title: 'An error occurred'
            }
            dispatch(addMessage(msg))
        }
        dispatch(deleteTaskByTitle(taskTitle))
    }

};
export const logoutUser = (): AppThunk => async (dispatch) => {
    dispatch(clearToken())
    dispatch(setUserData(null))
    dispatch(resetRefinements(null))
    dispatch(reconnectToHub())
    dispatch(resetGenreList())
    navigate('/', { replace: true })
};
export const registerUser = (model: RegisterUserModel): AppThunk => async (dispatch) => {
    const taskTitle = "Registering user..."
    dispatch(addTask({ id: 0, title: taskTitle }))
    const url = baseApiUrl + '/account/register'

    let msg: Message = {
        uiFunction: UiFunction.Success,
        displayTimeout: 8000,
        id: 0
    }

    const rs: IResponse<string> = await executeFetchAsync(url, model, guestAccessToken, 'POST', false)
    if (rs.responseType > 0) {
        msg.uiFunction = UiFunction.Danger
        msg.title = rs.userMessage;
        msg.details = rs.systemMessage;
    }
    else {
        msg.title = "Success!"
        msg.details = rs.userMessage;
        navigate("/");
    }

    dispatch(deleteTaskByTitle(taskTitle))
    dispatch(addMessage(msg))
}
export const resetVisibleMovies = (): AppThunk => (dispatch) => {
    dispatch(clearMovieList())
    dispatch(resetGenreList())
};

// Main slice exports
export const { clearToken, setSeenGetStarted, setUserData, setUserToken } = appUserSlice.actions;
export default appUserSlice.reducer;
