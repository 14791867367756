import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../context/store';
import { executeFetchAsync, getUniqueListBy } from '../../globals/utilities';
import { baseApiUrl, guestAccessToken } from '../../context/apiConfig';
import { Genre } from '../genres/genresSlice';
import { Credits, CreditType } from '../credits/creditSlice';

export type MediaImage = {
    aspect_ratio: number
    file_path: string
    height: number
    iso_639_1: string
    vote_average: number
    vote_count: number
    width: number
}

export type MediaFolder = {
    backdrops: MediaImage[]
    posters: MediaImage[]
}

export type PurchaseOption = {
    display_priority: number
    logo_path: string
    provider_id: number
    provider_name: string
}

export type ProviderCountry = {
    buy: PurchaseOption[]
    flatrate: PurchaseOption[]
    link: string
    rent: PurchaseOption[]
}

export type MovieProviderCountry = {
    us: ProviderCountry
}

export type Video = {
    embed_iframe: string
    id: string
    name: string
}

export type Videos = {
    results: Video[]
}

export type Movie = {
    adult: boolean,
    backdrop_path: string,
    credits: Credits,
    details_loaded: boolean,
    genres: Genre[],
    id: number,
    images: MediaFolder,
    overview: string
    popularity: number,
    poster_path: string,
    provider_countries: MovieProviderCountry
    release_date: string,
    tagline: string,
    title: string,
    video: boolean,
    videos: Videos,
}

export type MoviesState = {
    movieList: Movie[]
}

const initialState: MoviesState = {
    movieList: []
}

export const movieSlice = createSlice({
    name: 'movies',
    initialState: initialState,
    reducers: {
        addMovies: (state: MoviesState, action) => {
            state.movieList = getUniqueListBy("id", state.movieList, action.payload);
        },
        clearMovieList: (state: MoviesState) => {
            state.movieList = []
        },
        upsertMovie: (state: MoviesState, action) => {
            let updated = false;
            state.movieList = state.movieList.map(m => {
                if (m.id === action.payload.id) {
                    updated = true;
                    return action.payload;
                }
                else {
                    return m;
                }
            })
            if (!updated) {
                state.movieList.push(action.payload);
            }
        }
    },
});

// Base selectors
export const selectNumber = (_: RootState, n: number) => n;
export const selectMoviesBase = (state: RootState) => state.movies.movieList;

// Reselectors
export const selectMovies = createSelector(selectMoviesBase, movies => movies)
export const selectMovieById = createSelector(
    selectMoviesBase,
    selectNumber,
    (items, movieId) => {
        return items.find(i => i.id === movieId)
    });

// Methods
export const ensureMovie = (id: number): AppThunk => async (dispatch, getState) => {
    const stateMovies = getState().movies.movieList
    const existing = stateMovies.find(m => m.id === id);

    if (!existing) {
        const url = baseApiUrl + '/movies/' + id
        const rs: Movie = await executeFetchAsync(url, null, guestAccessToken)
        if (rs) {
            dispatch(upsertMovie(rs))
        }
        else {
            console.warn('No movie for ' + id)
        }
    }
};
export const ensureMovies = (ids: number[]): AppThunk => async (dispatch) => {
    ids.forEach(id => {
        dispatch(ensureMovie(id))
    });
};
export const ensureMovieDetails = (id: number): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const stateMovies = state.movies.movieList
    let returnValue = stateMovies.find(m => m.id === id);

    if (!returnValue || returnValue.details_loaded === false) {
        const url = baseApiUrl + '/movies/' + id + '/details'
        returnValue = await executeFetchAsync(url, null, guestAccessToken)

        if (returnValue) {
            returnValue?.credits?.cast?.forEach(c => c.creditType = CreditType.Person)
            returnValue?.credits?.crew?.forEach(c => c.creditType = CreditType.Person)
            dispatch(upsertMovie(returnValue))
        }
        else {
            console.warn('No movie for ' + id)
        }
    }

    return returnValue
};

// Main slice exports
export const { addMovies, clearMovieList, upsertMovie } = movieSlice.actions;
export default movieSlice.reducer;