import { isBrowser } from "../globals/utilities"

// Loc
// export const baseUrl: string = 'http://192.168.1.182:8000'
// export const baseApiUrl: string = 'https://localhost:44309/api'
// export const hubUrl: string = 'https://localhost:44309/kixvuHub'


// Prod
export const baseUrl: string = 'https://www.kixvu.com'
export const baseApiUrl: string = 'https://kixvu-api-prod.azurewebsites.net/api'
export const hubUrl: string = 'https://kixvu-api-prod.azurewebsites.net/kixvuHub'

export const guestAccessToken: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiZ3Vlc3RAa2l4dnUuY29tIiwibmJmIjoiMTcwMTk4NjkxNyIsImV4cCI6MTczMzYwOTMxNywic3ViIjoiZ3Vlc3RAa2l4dnUuY29tIiwianRpIjoiMjhmODExYTktZTBlMC00Yzk0LThkMDEtNDViMjhjNjU5OTdjIiwiU2NyZWVuTmFtZSI6Imd1ZXN0IiwiaXNzIjoiaHR0cHM6Ly93d3cua2l4dnUuY29tIiwiYXVkIjoiaHR0cHM6Ly93d3cua2l4dnUuY29tIn0.qL598_7Zt55szES64XGgBJ4JHVFVB7hJicdenixDNws'

export interface IResponse<T> {
    requestId: string
    responseId: string
    responseType: number
    result: T
    systemMessage?: string
    userMessage?: string
}
