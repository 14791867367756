import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../context/store'
import providersData from './providers-us.json'

export type Provider = {
    logo_path: string
    provider_name: string
    provider_id: number
}

export type ProviderState = {
    list: Provider[]
}

export const initialState: ProviderState = {
    list: providersData.results.sort((a, b) => {
        if (a.provider_name < b.provider_name)
            return -1;
        if (a.provider_name > b.provider_name)
            return 1;
        return 0;
    })
}

export const providersSlice = createSlice({
    name: 'providers',
    initialState: initialState,
    reducers: {
    },
});

// Base Selectors
export const selectProvidersBase = (state: RootState) => state.providers.list;

// Reselectors
export const selectProviders = createSelector(selectProvidersBase, (items) => items);

export const { } = providersSlice.actions;
export default providersSlice.reducer;
