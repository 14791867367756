import { createSelector, createSlice } from '@reduxjs/toolkit';
import { emptyGuid, executeFetchAsync, getStorageItem, setStorageItem } from '../../globals/utilities';
import { baseApiUrl } from '../../context/apiConfig';
import { AppThunk, RootState } from '../../context/store';
import { resetVisibleMovies, selectUserToken } from '../appUser/appUserSlice';
import { removeGenreMovie } from '../genres/genresSlice';
import { selectMovies } from '../movies/movieSlice';

const storageKey = process.env.STATE_NAME + "-refinements"

export type Rating = {
    id: string
    inWatchlist: boolean
    isHidden: boolean
    mediaType: string
    movieId: number
    stars: number
    tvId: number
}

export type RefinementChannel = {
    id: string,
    channelId: number
}

export type RefinementsState = {
    id: string
    refinementChannels: RefinementChannel[]
    ratingPage: number
    ratings: Rating[]
    showAllChannels: boolean
    showHidden: boolean
    showRated: boolean
    showWatchList: boolean
    sortAscending: boolean
    sortBy: string
    userId: string
}

const initialState: RefinementsState = {
    id: emptyGuid,
    refinementChannels: [],
    ratingPage: 1,
    ratings: [],
    showAllChannels: false,
    showHidden: false,
    showRated: false,
    showWatchList: false,
    sortAscending: false,
    sortBy: 'vote_count',
    userId: "guest@kixvu.com",
}

export const refinementSlice = createSlice({
    name: 'refinements',
    initialState: getStorageItem(storageKey) as RefinementsState || initialState,
    reducers: {
        addChannel: (state: RefinementsState, action) => {
            state.refinementChannels = [
                ...state.refinementChannels,
                action.payload
            ];
            setStorageItem(storageKey, state)
        },
        clearRatings: (state: RefinementsState) => {
            state.ratings = [];
            setStorageItem(storageKey, state)
        },
        deleteMovieRating: (state: RefinementsState, action) => {
            state.ratings = [
                ...state.ratings.filter(i => i.movieId !== action.payload)
            ]
            setStorageItem(storageKey, state)
        },
        increaseRatingPage: (state: RefinementsState) => {
            state.ratingPage++
        },
        removeChannel: (state: RefinementsState, action) => {
            state.refinementChannels = [
                ...state.refinementChannels.filter(i => i.channelId !== action.payload)
            ]
            setStorageItem(storageKey, state)
        },
        resetRatingPage: (state: RefinementsState) => {
            state.ratingPage = 1
        },
        resetRefinements: (state: RefinementsState, action) => {
            state.id = emptyGuid;
            state.refinementChannels = [];
            state.ratings = [];
            state.showAllChannels = false;
            state.showHidden = false;
            state.showRated = false;
            state.showWatchList = false;
            state.userId = "guest@kixvu.com"
            setStorageItem(storageKey, state)
        },
        setRatings: (state: RefinementsState, action) => {
            state.ratings = action.payload;
            setStorageItem(storageKey, state)
        },
        setRefinements: (state: RefinementsState, action) => {
            state.id = action.payload.id;
            state.refinementChannels = action.payload.refinementChannels;
            state.ratings = action.payload.ratings;
            state.showAllChannels = action.payload.showAllChannels;
            state.showHidden = action.payload.showHidden;
            state.showRated = action.payload.showRated;
            state.showWatchList = action.payload.showWatchList;
            state.userId = action.payload.userId
            setStorageItem(storageKey, state)
        },
        setShowAllChannels: (state: RefinementsState, action) => {
            state.showAllChannels = action.payload;
            setStorageItem(storageKey, state)
        },
        setShowHidden: (state: RefinementsState, action) => {
            state.showHidden = action.payload;
            setStorageItem(storageKey, state)
        },
        setShowRated: (state: RefinementsState, action) => {
            state.showRated = action.payload;
            setStorageItem(storageKey, state)
        },
        setShowWatchList: (state: RefinementsState, action) => {
            state.showWatchList = action.payload;
            setStorageItem(storageKey, state)
        },
        upsertMovieRating: (state: RefinementsState, action) => {
            const existingItem = state.ratings.find(item => action.payload.movieId === item.movieId)
            if (existingItem) {
                existingItem.id = action.payload.id
                existingItem.inWatchlist = action.payload.inWatchlist
                existingItem.isHidden = action.payload.isHidden
                existingItem.stars = action.payload.stars
            }
            else {
                state.ratings.push(action.payload)
            }
            setStorageItem(storageKey, state)
        },
    },
});

// Base selectors
export const selectNumber = (_: RootState, id: number) => id;
export const selectRefinementsBase = (state: RootState) => state.refinements;
export const selectRatingsBase = (state: RootState) => state.refinements.ratings;
export const selectRatingsPageBase = (state: RootState) => state.refinements.ratingPage;
export const selectRefinementChannelsBase = (state: RootState) => state.refinements.refinementChannels

// Reselectors
export const selectRefinements = createSelector(selectRefinementsBase, (val: RefinementsState) => val);
export const selectHiddenList = createSelector(
    selectRefinementsBase,
    (refinements: RefinementsState) => {
        return refinements.ratings.filter(i => i.isHidden === true)
    }
)
export const selectRatedList = createSelector(
    selectRefinementsBase,
    (refinements: RefinementsState) => {
        return refinements.ratings.filter(i => i.stars > 0)
    }
)
export const selectWatchList = createSelector(
    selectRefinementsBase,
    (refinements: RefinementsState) => {
        return refinements.ratings.filter(i => i.inWatchlist === true)
    }
)
export const selectRatingByMovieId = createSelector(
    selectRatingsBase,
    selectNumber,
    (items: Rating[], id: number) => {
        return items.find(r => r.movieId === id)
    }
)
export const selectPagedRatings = createSelector(
    selectRatingsPageBase,
    selectRatingsBase,
    (page, ratings) => {
        const stared = ratings.filter((r: Rating) => { return (r.stars > 0) })
        let rows = (page * 20)
        if (rows > stared.length) rows = stared.length
        const subset = stared.slice(0, rows)
        return subset
    }
)
export const selectIsMyChannel = createSelector(
    selectRefinementChannelsBase,
    selectNumber,
    (channels: RefinementChannel[], id: number) => {
        return (channels.find(rc => rc.channelId === id))
            ? true
            : false
    })
export const selectIsInMyChannels = createSelector(
    selectMovies,
    selectNumber,
    selectRefinementChannelsBase,
    (movies, movieId, channels) => {
        if (!channels || channels.length === 0 || movieId === -1) return false;

        let returnValue = false;
        const movie = movies.find(m => m.id === movieId);
        const movieChannels = movie?.provider_countries?.us?.flatrate
        movieChannels?.forEach(mc => {
            const exists = channels.find(c => c.channelId === mc.provider_id);
            if (exists) {
                returnValue = true;
            }
        });

        return returnValue;
    }
);

// Methods
export const creatNewRating = () => {
    const returnValue: Rating = {
        id: emptyGuid,
        inWatchlist: false,
        isHidden: false,
        mediaType: 'movie',
        movieId: 0,
        stars: 0,
        tvId: 0,
    }
    return returnValue
}
export const setRating = (rating: Rating): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const refinements = state.refinements

    // Updated cached movies
    if (refinements.showRated === false && rating.stars > 0) {
        dispatch(removeGenreMovie(rating.movieId))
    }
    if (refinements.showWatchList === false && rating.inWatchlist === true) {
        dispatch(removeGenreMovie(rating.movieId))
    }
    if (refinements.showHidden === false && rating.isHidden === true) {
        dispatch(removeGenreMovie(rating.movieId))
    }

    // Update refinements
    const userToken = selectUserToken(state)
    if (rating.stars === 0 && !rating.inWatchlist && !rating.isHidden) {
        // Remove it
        dispatch(deleteMovieRating(rating.movieId))
        if (userToken) {
            const url = baseApiUrl + '/refinements/deleteRating'
            const rs = await executeFetchAsync(url, rating.id, userToken, true, 'DELETE')
        }
    }
    else {
        // Upsert it
        dispatch(upsertMovieRating(rating))
        if (userToken) {
            const url = baseApiUrl + '/refinements/upsertRating'
            const rs: Rating = await executeFetchAsync(url, rating, userToken)
            if (rating.id !== rs.id) {
                dispatch(upsertMovieRating(rs))
            }
        }
    }
};
export const toggleProvider = (provider_id: number): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const refinementChannels = state.refinements.refinementChannels
    const existingChannel = refinementChannels.find(c => c.channelId === provider_id)

    if (existingChannel) {
        dispatch(removeChannel(provider_id))
    }
    else {
        const newChannel: RefinementChannel = {
            channelId: provider_id,
            id: emptyGuid
        }
        dispatch(addChannel(newChannel))
    }

    dispatch(saveRefinements())
};
export const toggleShowAllChannels = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const newVal = !state.refinements.showAllChannels;
    dispatch(setShowAllChannels(newVal))
    dispatch(saveRefinements())
}
export const toggleShowHidden = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const newVal = !state.refinements.showHidden;
    dispatch(setShowHidden(newVal))
    dispatch(saveRefinements())
}
export const toggleShowRated = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const newVal = !state.refinements.showRated;
    dispatch(setShowRated(newVal))
    dispatch(saveRefinements())
}
export const toggleShowWatchList = (): AppThunk => (dispatch, getState) => {
    console.log('Clicked')
    const state = getState();
    const newVal = !state.refinements.showWatchList;
    dispatch(setShowWatchList(newVal))
    dispatch(saveRefinements())
}
export const saveRefinements = (): AppThunk => async (dispatch, getState) => {
    dispatch(resetVisibleMovies())

    const state = getState()
    const userToken = selectUserToken(state)
    if (userToken) {
        const url = baseApiUrl + '/refinements/upsertRefinements'
        const rs: RefinementChannel = await executeFetchAsync(url, state.refinements, userToken)
    }
}

// Main slice exports
export const { addChannel, clearRatings, deleteMovieRating, increaseRatingPage, removeChannel,
    resetRatingPage, resetRefinements, setRatings, setRefinements, setShowAllChannels,
    setShowHidden, setShowRated, setShowWatchList, upsertMovieRating } = refinementSlice.actions;
export default refinementSlice.reducer;
