import { createSelector, createSlice } from "@reduxjs/toolkit";
import { baseApiUrl, guestAccessToken } from "../../context/apiConfig";
import { AppThunk, RootState } from "../../context/store";
import { executeFetchAsync, UiFunction } from "../../globals/utilities";
import { addMessage, getConnection, Message } from "../app/appSlice";
import { CreditType } from "../credits/creditSlice";
import { Person, upsertPerson } from "../people/peopleSlice";

// Kevin Bacon ID: 
// https://www.kixvu.com/people/4724

export type Player = {
    connectionId: string
    title: string
    wins: number
}

export type Tourney = {
    code: string
    createdDate?: string
    endPerson?: Person
    host?: Player
    players?: Player[]
    startPerson: Person
    winningSentances?: string[]
    winner?: string
}

export type BaconLink = {
    display: string
    id: number
    index: number
    isMovie: boolean
}

export type BaconState = {
    allPlayers: [],
    connectionStatus: string,
    endPerson?: Person,
    endPersonId: number,
    isComplete: boolean,
    isHost: boolean,
    isInitializing: boolean,
    baconLinks: BaconLink[],
    startSettings: null,
    tourney: Tourney | null,
}

const initialState: BaconState = {
    allPlayers: [],
    connectionStatus: "Offline",
    endPersonId: 4724,
    isComplete: false,
    isHost: false,
    isInitializing: false,
    baconLinks: [],
    startSettings: null,
    tourney: null
}

export const baconSlice = createSlice({
    name: 'bacon',
    initialState,
    reducers: {
        initialize: (state) => {
            state.isComplete = false;
            state.isInitializing = true;
            state.baconLinks = [];
            state.startSettings = null;
            state.tourney = null;
        },

        setBaconLink: (state, action) => {
            const bl: BaconLink = action.payload
            state.baconLinks.length = bl.index;
            state.baconLinks.push(bl);
            if (action.payload.index === 0)
                state.isInitializing = false;
        },

        setConnectionStatus: (state, action) => {
            state.connectionStatus = action.payload;
        },

        setEndPerson: (state, action) => {
            state.endPerson = action.payload;
            state.isInitializing = false;
        },

        setEndPersonId: (state, action) => {
            state.endPersonId = action.payload;
        },

        setIsComplete: (state, action) => {
            state.isComplete = action.payload;
        },

        setIsHost: (state, action) => {
            state.isHost = action.payload;
        },

        setStartSettings: (state, action) => {
            state.startSettings = action.payload;
        },

        startTourney: (state, action) => {
            state.tourney = action.payload;
            state.isComplete = false;
            state.isInitializing = true;
            state.baconLinks = [];
        },

        updateAllPlayers: (state, action) => {
            state.allPlayers = action.payload;
        },

        updateTourney: (state, action) => {
            state.tourney = action.payload;
        },
    },
});

// Base Selectors
export const selectBaconBase = (state: RootState) => state.bacon;
export const selectBaconLinksBase = (state: RootState) => state.bacon.baconLinks;
export const selectBaconTourneyBase = (state: RootState) => state.bacon.tourney;
export const selectBaconIsHostBase = (state: RootState) => state.bacon.isHost;

// Reselectors
export const selectNumber = (_: RootState, n: number) => n;
export const selectBacon = createSelector(selectBaconBase, (b) => b);
export const selectBaconLinks = createSelector(selectBaconLinksBase, (items) => items);
export const selectSentances = createSelector(
    selectBaconBase,
    (bacon) => {
        const items = bacon.baconLinks
        const returnValue = getSentances(items, bacon.endPersonId)
        return returnValue
    }
);
export const selectBaconLinkByIndex = createSelector(
    selectBaconLinksBase,
    selectNumber,
    (items, index) => {
        return items[index] || null
    }
)
export const selectPlayers = createSelector(selectBaconBase, (b) => b.tourney?.players);
export const selectEndPeronId = createSelector(selectBaconBase, (b) => b.endPersonId);
export const selectBaconTourney = createSelector(selectBaconTourneyBase, (i) => i);
export const selectBaconIsHost = createSelector(selectBaconIsHostBase, (i) => i);

// Methods
export const beginInitialization = (endPersonId: number): AppThunk => async (dispatch) => {
    dispatch(initialize());
    const url = baseApiUrl + '/people/random'
    const person: Person = await executeFetchAsync(url, null, guestAccessToken)

    person?.credits?.cast?.forEach(c => c.creditType = CreditType.Movie)
    person?.credits?.crew?.forEach(c => c.creditType = CreditType.Movie)

    dispatch(setEndPersonId(endPersonId))
    dispatch(upsertPerson(person))

    const startItem: BaconLink = {
        display: person.name,
        id: person.id,
        index: 0,
        isMovie: false,
    }

    dispatch(setBaconLink(startItem))
};

export const beginRound = (): AppThunk => (dispatch) => {
    const kvConnection = getConnection()
    kvConnection.invoke("BeginRound");
};

export const setSelectedBl = (bl: BaconLink): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const bacon = state.bacon;

    // dispatch not fast enough
    const existingLinks = [...bacon.baconLinks, bl]
    dispatch(setBaconLink(bl))

    if (bl.isMovie === false && bl.id === bacon.endPersonId) {
        dispatch(setIsComplete(true))

        if (bacon.tourney) {
            const sentances = getSentances(existingLinks, bacon.endPersonId)
            const kvConnection = getConnection()
            kvConnection.invoke("CompleteRound", bacon.tourney.code, sentances);
        }
        else {
            const msg: Message = {
                uiFunction: UiFunction.Success,
                displayTimeout: 8000,
                id: 0,
                title: 'Woohoo!',
                details: 'Scroll up to play again.<br/>Or, change one of your selections in the middle<br/>to try a different path.'
            }
            dispatch(addMessage(msg))
        }
    }
};

export const leaveTourney = (): AppThunk => (dispatch, getState) => {
    const bacon = getState().bacon
    if (bacon.tourney) {
        const kvConnection = getConnection()
        kvConnection.invoke('LeaveTourney');
        dispatch(updateTourney(null))
        dispatch(setIsHost(false))
    }
};

export const refreshTourney = (tourney: Tourney): AppThunk => (dispatch) => {
    dispatch(updateTourney(tourney))
    if (tourney && tourney.winningSentances && tourney.winningSentances.length > 0) {
        const details = tourney.winningSentances.join('<br/>')
        const msg: Message = {
            uiFunction: UiFunction.Success,
            displayTimeout: 0,
            id: 0,
            title: tourney.winner + " wins!",
            details
        }
        dispatch(addMessage(msg))
    }
};

export const roundStarting = (tourney: Tourney): AppThunk => (dispatch) => {
    dispatch(upsertPerson(tourney.startPerson))
    dispatch(upsertPerson(tourney.endPerson))

    dispatch(updateTourney(tourney));

    dispatch(setBaconLink({
        display: tourney.startPerson.name,
        id: tourney.startPerson.id,
        index: 0,
        isMovie: false
    }));

    dispatch(setEndPerson(tourney.endPerson));
}

export const startTourney = (playerName: string, code: string): AppThunk => async (dispatch, getState) => {
    const bacon = getState().bacon
    const kvConnection = getConnection()
    dispatch(initialize())
    dispatch(setStartSettings({ playerName, code }));

    let tourney: Tourney

    if (code && code.length > 0) {
        tourney = await kvConnection.invoke('RequestJoinTourney', playerName, code);
        dispatch(updateTourney(tourney));
        dispatch(setIsHost(false));
        if (!tourney) {
            const msg: Message = {
                uiFunction: UiFunction.Warning,
                displayTimeout: 5000,
                id: 0,
                title: "Oops!",
                details: "Either tourney " + code + " is invalid or has ended."
            }
            dispatch(addMessage(msg))
        }
    }
    else {
        const endPersonId = bacon.endPersonId
        tourney = await kvConnection.invoke('RequestStartTourney', playerName, endPersonId)
        dispatch(updateTourney(tourney))
        dispatch(setIsHost(true))
    }
};


export const getSentances = (items: BaconLink[], endPersonId: number) => {
    const returnValue: string[] = []
    if (items && items.length > 0) {
        for (let actorIndex = 0; actorIndex < items.length; actorIndex += 2) {
            if (items[actorIndex].id !== endPersonId) {
                let sentance: string = items[actorIndex].display
                const movieIndex = actorIndex + 1
                if (items.length > movieIndex) {
                    sentance += ' appears in "' + items[movieIndex].display + '"'
                }
                const secondActor = movieIndex + 1
                if (items.length > secondActor) {
                    sentance += ' with ' + items[secondActor].display
                }
                returnValue.push(sentance)
            }
        }
    }
    return returnValue;
}

// Store exports
export const { initialize, setConnectionStatus, setEndPerson, setEndPersonId, setIsComplete, setIsHost,
    setBaconLink, setStartSettings, updateAllPlayers, updateTourney } = baconSlice.actions;
export default baconSlice.reducer;

// 🕺 🎦 🎥 🎬
// 🎭 &#127917;
// 🎞 &#127902;
