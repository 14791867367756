import { createSelector, createSlice } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "../../context/store"
import { selectAnyToken } from "../appUser/appUserSlice";
import { hubUrl } from "../../context/apiConfig";
const signalR = require("@microsoft/signalr")
import { HubConnection } from '@microsoft/signalr'
import { addMovies, Movie, upsertMovie } from "../movies/movieSlice";
import { Person, upsertPerson } from "../people/peopleSlice";
import { addGenreMovies, DiscoverRequest } from "../genres/genresSlice";
import { UiFunction } from "../../globals/utilities";
import { refreshTourney, roundStarting } from "../bacon/baconSlice";

let kvConnection: HubConnection;

export type MultiResult = {
    movies: Movie[]
    people: Person[]
}

export type MultiResultIds = {
    movies: number[]
    people: number[]
}

const initialMultiResultIds: MultiResultIds = {
    movies: [],
    people: [],
}

export type Message = {
    uiFunction: UiFunction
    details?: string
    displayTimeout: number
    id: number
    title?: string
}

export type Task = {
    id: number
    title: string
}

export type AppState = {
    connectionStatus: string
    messageList: Message[]
    multiResultIds: MultiResultIds
    taskList: Task[]
}

const initialState: AppState = {
    connectionStatus: 'Closed',
    messageList: [],
    multiResultIds: initialMultiResultIds,
    taskList: [],
}

export const messagesSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        addMessage: (state: AppState, action) => {
            let newId = Math.max(...state.messageList.map(o => o.id), 0)
            newId++
            action.payload.id = newId
            state.messageList.push(action.payload)
        },
        clearMessages: (state) => {
            state.messageList = [];
        },
        deleteMessage: (state: AppState, action) => {
            state.messageList = state.messageList.filter(message => action.payload !== message.id)
        },

        addTask: (state: AppState, action) => {
            let newId = Math.max(...state.taskList.map(o => o.id), 0)
            newId++
            action.payload.id = newId
            state.taskList.push(action.payload)
        },
        clearTasks: (state) => {
            state.taskList = [];
        },
        deleteTask: (state: AppState, action) => {
            state.taskList = state.taskList.filter(o => action.payload !== o.id)
        },
        deleteTaskByTitle: (state: AppState, action) => {
            state.taskList = state.taskList.filter(o => action.payload !== o.title)
        },

        setConnectionStatus: (state: AppState, action) => {
            state.connectionStatus = action.payload;
        },

        clearResults: (state: AppState) => {
            state.multiResultIds.movies = []
            state.multiResultIds.people = []
        },
        setResults: (state: AppState, action) => {
            state.multiResultIds = action.payload
        },
    },
});

// Base selectors
export const selectMessagesBase = (state: RootState) => state.app.messageList;
export const selectTasksBase = (state: RootState) => state.app.taskList;
export const selectConnectionStatusBase = (state: RootState) => state.app.connectionStatus;
export const selectResultsBase = (state: RootState) => state.app.multiResultIds;

// Reselectors
export const selectMessages = createSelector(selectMessagesBase, (items) => items)
export const selectTasks = createSelector(selectTasksBase, (items) => items)
export const selectConnectionStatus = createSelector(selectConnectionStatusBase, (status) => status)

// Methods
export const connectToHub = (): AppThunk => async (dispatch, getState) => {
    if (kvConnection && kvConnection.state === "Connected")
        return kvConnection;

    const state = getState()
    const currentStatus = state.app.connectionStatus
    if (currentStatus !== 'Closed')
        return;

    dispatch(setConnectionStatus('Connecting'));
    
    const token = selectAnyToken(state)

    kvConnection = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl, { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .build();

    // Connection events
    kvConnection.onreconnecting(() => { dispatch(setConnectionStatus(kvConnection.state)); })
    kvConnection.onreconnected(() => { dispatch(setConnectionStatus(kvConnection.state)); })
    kvConnection.onclose(() => { dispatch(setConnectionStatus(kvConnection.state)); })

    // Browsing
    kvConnection.on("ReceiveMovie", movie => { dispatch(upsertMovie(movie)); });
    kvConnection.on("ReceiveMovies", movies => { dispatch(addMovies(movies)); });
    kvConnection.on("ReceivePerson", item => { dispatch(upsertPerson(item)); });

    // Bacon
    kvConnection.on("PlayerLeft", (screenName: string) => {
        const msg: Message = {
            uiFunction: UiFunction.Warning,
            displayTimeout: 5000,
            id: 0,
            title: ' ',
            details: screenName + ' has left the game.'
        }
        dispatch(addMessage(msg))
    });
    kvConnection.on("RoundStarting", (tourney) => {
        console.log("RoundStarting")
        // console.log(tourney)
        dispatch(roundStarting(tourney))
    });
    kvConnection.on("TourneyUpdated", (tourney) => {
        dispatch(refreshTourney(tourney))
    });

    const returnValue = await kvConnection.start()
        .then(() => {
            dispatch(setConnectionStatus(kvConnection.state));
            return kvConnection;
        })
        .catch((err) => {
            console.error(err)
            dispatch(setConnectionStatus(kvConnection.state));
            return null;
        });

    return returnValue;
};

export const disconnectHub = (): AppThunk => async (dispatch) => {
    if (kvConnection && kvConnection.state === "Connected")
        kvConnection.stop()
    dispatch(setConnectionStatus("Closed"));
};

export const invokeSearchMulti = (query: string, page: number = 1): AppThunk => async (dispatch) => {
    if (query.length > 2) {
        await dispatch(connectToHub());
        const rs: MultiResult = await kvConnection.invoke("SearchMulti", query, page);

        const movies: number[] = []
        const people: number[] = []

        rs.movies.forEach(m => {
            movies.push(m.id)
            dispatch(upsertMovie(m))
        });
        rs.people.forEach(p => {
            people.push(p.id)
            dispatch(upsertPerson(p))
        });

        dispatch(setResults({ movies, people }))
    }
    else {
        dispatch(clearResults())
    }
};

export const invokeDiscoverMovies = (discoverRequest: DiscoverRequest): AppThunk => async (dispatch) => {
    await dispatch(connectToHub());

    const rs: Movie[] = await kvConnection.invoke("DiscoverMovies", discoverRequest);

    if (rs) {
        dispatch(addMovies(rs))
        const movie_ids: number[] = [];
        rs.forEach(movie => {
            movie_ids.push(movie.id)
        });
        const genreId = discoverRequest.genreId;
        dispatch(addGenreMovies({ genreId, movie_ids }))
    }
};

export const reconnectToHub = (): AppThunk => async (dispatch) => {
    dispatch(disconnectHub());
    dispatch(connectToHub());
};

export function getConnection() {
    return kvConnection
}

// Main Exports
export const { addMessage, clearMessages, clearResults, deleteMessage, addTask, clearTasks,
    deleteTask, deleteTaskByTitle, setConnectionStatus, setResults } = messagesSlice.actions;
export default messagesSlice.reducer;
